import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <pre className="ascii-art">
        {`
 ██▓      ▄████  ███▄ ▄███▓       ███▄ ▄███▓ ▄▄▄        █████▒ ██▓ ▄▄▄      
▓██▒     ██▒ ▀█▒▓██▒▀█▀ ██▒      ▓██▒▀█▀ ██▒▒████▄    ▓██   ▒ ▓██▒▒████▄    
▒██░    ▒██░▄▄▄░▓██    ▓██░  ██  ▓██    ▓██░▒██  ▀█▄  ▒████ ░ ▒██▒▒██  ▀█▄  
▒██░    ░▓█  ██▓▒██    ▒██   ▓   ▒██    ▒██ ░██▄▄▄▄██ ░▓█▒  ░ ░██░░██▄▄▄▄██ 
░██████▒░▒▓███▀▒▒██▒   ░██▒  ░   ▒██▒   ░██▒ ▓█   ▓██▒░▒█░    ░██░ ▓█   ▓██▒
░ ▒░▓  ░ ░▒   ▒ ░ ▒░   ░  ░      ░ ▒░   ░  ░ ▒▒   ▓▒█░ ▒ ░    ░▓   ▒▒   ▓▒█░
░ ░ ▒  ░  ░   ░ ░  ░      ░  ░   ░  ░      ░  ▒   ▒▒ ░ ░       ▒ ░  ▒   ▒▒ ░
  ░ ░   ░ ░   ░ ░      ░         ░      ░     ░   ▒    ░ ░     ▒ ░  ░   ▒   
    ░  ░      ░        ░                ░         ░  ░         ░        ░  ░
        `}
      </pre>
    </div>
  );
}

export default App;
